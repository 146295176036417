*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

i {
  font-size: 1rem;
}

.light {
  background-color: white;
  color: black;
}

.dark {
  background-color: #333;
  color: white;
}

nav {
  padding: 0.25rem 0.5rem;
  text-align: center;
  width: 100%;
  position: sticky;
  animation: slideDown 0.5s linear;
  background-color: black;
  color: #efb141;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  h3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.forminput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: slideUp 0.5s linear;
  background-color: black;
  color: #efb141;
}

.forminput input {
  padding: 0.25rem;
  width: 50vw;
  margin: 1rem;

  &::placeholder {
    font-style: italic;
    font-size: smaller;
    padding: 0.25rem;
    color: inherit;
  }
}

#searchBtn {
  padding: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    color: white;
    background-color: #333;
  }
}

.movieinfocontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 60vw;
  border: 1px dashed grey;
  padding: 0.25rem;
}

#info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
}

p {
  line-height: 1.25rem;
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
  }

  to {
    transform: translateY(0);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 3rem;
  left: 0;

  .loader {
    border: 1rem solid white;
    border-top: 1rem solid transparent;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 0.5s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 768px) {
  .movieinfocontainer {
    flex-wrap: wrap;
    width: 100vw;
    border: none;
    padding: 1rem;
    margin-top: 3rem;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
  }

  .forminput {
    padding: 0.25rem;
    font-size: 0.75rem;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  #searchBtn {
    padding: 0.25rem;
    height: 2rem;
  }

  #info {
    height: 70%;
  }
}
